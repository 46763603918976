import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { setLoginOptions } from "../../actions/login-options";
import {
    checkTsIdConnected,
    setCallbackState,
    tsIdV3Login,
    completeInvitation,
    connectTsId
} from "../../actions/tsid-v3";

import { Spinner } from "../../components/general/spinner";
import Base64Utils from "../../utils/base64-utils";
import { sendLoginCompleteEvent } from "../../utils/send-login-complete-event";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { AUTOLINK_USER, SKIP_LINKING_FLAG } from "../../config";

const FlowTypes = {
    completeInvitation: "completeInvitation"
};

const CallbackView = () => {
    const tsIdV3 = useAppSelector(state => state.tsIdV3);
    const connectTsIdStatus = useAppSelector(state => state.tsIdV3.connectTsId.status);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const stateUriDecoded = decodeURIComponent(searchParams.get("state") || "");
    const state = useMemo(() => Base64Utils.decode(stateUriDecoded), [stateUriDecoded]);
    const { appName, flowType, referrer: originalReferrer, userEmail, skipLinking } = state;
    const tokenData = tsIdV3.login.tokenData;
    const loginResult = tsIdV3.login.loginResult;

    useEffect(() => {
        dispatch(setCallbackState(state));
    }, [state, dispatch]);

    useEffect(() => {
        dispatch(setLoginOptions({ appName }));
    }, [appName, dispatch]);

    useEffect(() => {
        if (!tsIdV3.login.status.started && !tsIdV3.login.status.ended && !tsIdV3.login.status.error) {
            dispatch(
                tsIdV3Login({ code: searchParams.get("code")!!, codeVerifier: localStorage.getItem("code_verifier")!! })
            );
        }
    }, [dispatch, tsIdV3.login.status.started, tsIdV3.login.status.ended, tsIdV3.login.status.error, searchParams]);

    useEffect(() => {
        if (tsIdV3.login.status.ended && tokenData.email && tokenData.sub) {
            dispatch(checkTsIdConnected({ tsId: tokenData.email, tsUserCode: tokenData.sub }));
        }
    }, [dispatch, tokenData, tsIdV3.login.status.ended]);

    useEffect(() => {
        if (connectTsIdStatus.started) {
            navigate("/connectAccountFeedback", { replace: true });
        }
    }, [navigate, connectTsIdStatus]);

    const completeInvitationLogic = () => {
        const { started, error, ended } = tsIdV3.completeInvitation.status;
        const completeInvitationNotInProgressNorEnded = !started && !error && !ended;
        const completeInvitationEnded = error || ended;

        if (completeInvitationNotInProgressNorEnded && tokenData.email && loginResult.accessToken) {
            dispatch(completeInvitation({ tsId: tokenData.email, token: loginResult.accessToken }));
        } else if (completeInvitationEnded) {
            navigate("/inviteCompleteFeedback", { replace: true });
        }
    };

    const completeInvitationHandler = () => {
        if (userEmail !== tokenData.email) {
            return navigate("/inviteFeedback", { replace: true });
        }

        completeInvitationLogic();
    };

    const defaultFlowHandler = () => {
        if (!tsIdV3.checkTsIdConnected.status.ended) {
            return;
        }

        if (tsIdV3.checkTsIdConnected.result.tsIdConnected) {
            return sendLoginCompleteEvent(
                loginResult.accessToken || "notset",
                loginResult.refreshToken || "notset",
                loginResult.idToken || "notset",
                originalReferrer,
                state.mode,
                state.appId,
                state.deepLink
            );
        }

        if (tsIdV3.checkTsIdConnected.result.localUser) {
            const notAlreadyConnecting = !connectTsIdStatus.started && !connectTsIdStatus.ended
            if (AUTOLINK_USER && notAlreadyConnecting) {
                const userId = tokenData.email || "notset";
                const tsIdToken = loginResult.accessToken || "notset";
                return dispatch(connectTsId({ userId, tsIdToken }));
            } else if (!skipLinking && !SKIP_LINKING_FLAG) {
                return navigate("/connectTSIdToDigitalAccount", { replace: true });
            }
        }

        if (tsIdV3.checkTsIdConnected.result.invitedUser) {
            return completeInvitationLogic();
        }

        navigate("/firstAccess", { replace: true });
    };

    if (tsIdV3.login.status.ended) {
        switch (flowType) {
            case FlowTypes.completeInvitation:
                completeInvitationHandler();
                break;
            default:
                defaultFlowHandler();
                break;
        }
    }

    if (!searchParams.get("code")) {
        navigate("/", { replace: true });
    }

    return <Spinner loading={true} />;
};

export default CallbackView;

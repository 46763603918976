import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import sha256 from "sha256";

import { getNonce } from "../auth";
import { postRestCall } from "../../utils/rest-api-call";
import { API_URL } from "../../config";

export const connectTsIdPrefix = "CONNECT_TS_ID";
export const CONNECT_TS_ID_RESET = `${connectTsIdPrefix}/reset`;

export type ConnectTsIdParameters = { userId: string; password?: string; tsIdToken: string };
export const connectTsId = createAsyncThunk(
    connectTsIdPrefix,
    async ({ userId, password, tsIdToken }: ConnectTsIdParameters) => {
        const nonce = await getNonce(userId);
        await postRestCall(
            `${API_URL}/login/tsid/v3/connect`,
            { securityToken: tsIdToken },
            {},
            { tsDigitalDigest: password && sha256(sha256(userId + password) + nonce), tsDigitalId: userId }
        );

        return { userId };
    }
);

export const connectTsIdReset = createAction(CONNECT_TS_ID_RESET);

export const DOMAIN = process.env.REACT_APP_DOMAIN || "-dev.agyo.io";
export const ENV = process.env.REACT_APP_ENV || "dev";

export const SSO_LOGIN = process.env.REACT_APP_SSO_LOGIN || "http://localhost";
export const SSO_REGISTER = process.env.REACT_APP_SSO_REGISTER || "http://localhost";
export const SSO_RESET_PASSWORD = process.env.REACT_APP_SSO_RESET_PASSWORD || "http://localhost";

let enableLogs = true;
if (process.env.REACT_APP_ENABLE_CONSOLE_LOGS === "false") {
    enableLogs = false;
}

export const ENABLE_CONSOLE_LOGS = enableLogs;
export const API_URL = process.env.REACT_APP_API_URL || "http://localhost";
export const PORTALE_API_URL = process.env.REACT_APP_PORTALE_API_URL || "https://f.portale.api.agyo.io";
export const PORTAL_URL = process.env.REACT_APP_PORTAL_URL || "http://localhost";

export const PUBLIC_URL = process.env.PUBLIC_URL || "";

export const TS_DIGITAL_IMAGES =
    process.env.REACT_APP_TS_DIGITAL_IMAGES || "https://appdev.teamsystemdigital.com/images/ts-digital/";

export const PRIVACY_POLICY_URL =
    process.env.REACT_APP_PRIVACY_POLICY_URL || "https://tc.teamsystem.com/InformativaPrivacyTSDigital.pdf";

export const API_CAPTCHA_SIGNUP_URL = process.env.REACT_APP_API_CAPTCHA_SIGNUP_URL || "http://localhost";
export const CAPTCHA_SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;

export const TS_ID_AUTHORIZATION_ENDPOINT = process.env.REACT_APP_TS_ID_AUTHORIZATION_ENDPOINT || "http://localhost";
export const TS_ID_END_SESSION_ENDPOINT = process.env.REACT_APP_TS_ID_END_SESSION_ENDPOINT || "http://localhost";
export const TS_ID_CLIENT_ID = process.env.REACT_APP_TS_ID_CLIENT_ID || "<missing_client_id>";
export const TS_ID_CALLBACK_URL = process.env.REACT_APP_TS_ID_CALLBACK_URL || "http://localhost";
export const TS_ID_POST_LOGOUT_REDIRECT_URL =
    process.env.REACT_APP_TS_ID_POST_LOGOUT_REDIRECT_URL || "http://localhost";
export const DIGITAL_URL = process.env.REACT_APP_DIGITAL_URL || "http://localhost";

export const DEFAULT_APPLICATION = process.env.REACT_APP_DEFAULT_APPLICATION || "PORTALE";
export const SKIP_LINKING_FLAG = process.env.REACT_APP_SKIP_LINKING_FLAG === "true";
export const AUTOLINK_USER = process.env.REACT_APP_AUTOLINK_USER === "true";
